// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DashboardCardCounters {
    dashboardCardCounters {
      title
      count
      link
      icon
    }
    dashboardListSlugs
  }
`;

export default QUERY;
